#edit-loan-amortization {
  .input-cell {
    display: flex;
    align-items: center;
    justify-content: left;

    .out-of {
      margin: 0 6px;
    }
  }

  .allow-overflow {
    overflow: visible;
  }
}
